import { render, staticRenderFns } from "./StudentList.vue?vue&type=template&id=25673190&scoped=true&"
import script from "./StudentList.vue?vue&type=script&lang=js&"
export * from "./StudentList.vue?vue&type=script&lang=js&"
import style0 from "./StudentList.vue?vue&type=style&index=0&id=25673190&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25673190",
  null
  
)

export default component.exports